// creditPointConstants.js

export const CREDIT_POINT_REQUEST_CREATE_REQUEST = 'CREDIT_POINT_REQUEST_CREATE_REQUEST';
export const CREDIT_POINT_REQUEST_CREATE_SUCCESS = 'CREDIT_POINT_REQUEST_CREATE_SUCCESS';
export const CREDIT_POINT_REQUEST_CREATE_FAIL = 'CREDIT_POINT_REQUEST_CREATE_FAIL';

export const CREDIT_POINT_LIST_REQUEST = 'CREDIT_POINT_LIST_REQUEST';
export const CREDIT_POINT_LIST_SUCCESS = 'CREDIT_POINT_LIST_SUCCESS';
export const CREDIT_POINT_LIST_FAIL = 'CREDIT_POINT_LIST_FAIL';

export const CREDIT_POINT_ALL_LIST_REQUEST = 'CREDIT_POINT_ALL_LIST_REQUEST';
export const CREDIT_POINT_ALL_LIST_SUCCESS = 'CREDIT_POINT_ALL_LIST_SUCCESS';
export const CREDIT_POINT_ALL_LIST_FAIL = 'CREDIT_POINT_ALL_LIST_FAIL'; 

export const CREDIT_POINT_BALANCE_REQUEST = 'CREDIT_POINT_BALANCE_REQUEST';
export const CREDIT_POINT_BALANCE_SUCCESS = 'CREDIT_POINT_BALANCE_SUCCESS';
export const CREDIT_POINT_BALANCE_FAIL = 'CREDIT_POINT_BALANCE_FAIL'; 

export const GET_USER_CREDIT_POINT_PAYMENTS_REQUEST = 'CREDIT_POINT_ALL_LIST_REQUEST';
export const GET_USER_CREDIT_POINT_PAYMENTS_SUCCESS = 'CREDIT_POINT_ALL_LIST_SUCCESS';
export const GET_USER_CREDIT_POINT_PAYMENTS_FAIL = 'CREDIT_POINT_ALL_LIST_FAIL';

export const GET_ALL_CREDIT_POINT_PAYMENTS_REQUEST = 'CREDIT_POINT_ALL_LIST_REQUEST';
export const GET_ALL_CREDIT_POINT_PAYMENTS_SUCCESS = 'CREDIT_POINT_ALL_LIST_SUCCESS';
export const GET_ALL_CREDIT_POINT_PAYMENTS_FAIL = 'CREDIT_POINT_ALL_LIST_FAIL';

export const CREDIT_POINT_EARNINGS_REQUEST = 'CREDIT_POINT_EARNINGS_REQUEST';
export const CREDIT_POINT_EARNINGS_SUCCESS = 'CREDIT_POINT_EARNINGS_SUCCESS';
export const CREDIT_POINT_EARNINGS_FAIL = 'CREDIT_POINT_EARNINGS_FAIL';



