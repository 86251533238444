export const USER_FUND_ACCOUNT_REQUEST = 'USER_FUND_ACCOUNT_REQUEST';
export const USER_FUND_ACCOUNT_SUCCESS = 'USER_FUND_ACCOUNT_SUCCESS';
export const USER_FUND_ACCOUNT_FAIL = 'USER_FUND_ACCOUNT_FAIL';

export const USER_ACCOUNT_FUND_BALANCE_REQUEST = 'USER_ACCOUNT_FUND_BALANCE_REQUEST';
export const USER_ACCOUNT_FUND_BALANCE_SUCCESS = 'USER_ACCOUNT_FUND_BALANCE_SUCCESS';
export const USER_ACCOUNT_FUND_BALANCE_FAIL = 'USER_ACCOUNT_FUND_BALANCE_FAIL';

export const GET_All_ACCOUNT_FUND_BALANCE_REQUEST = 'GET_All_ACCOUNT_FUND_BALANCE_REQUEST';
export const GET_All_ACCOUNT_FUND_BALANCE_SUCCESS = 'GET_All_ACCOUNT_FUND_BALANCE_SUCCESS'; 
export const GET_All_ACCOUNT_FUND_BALANCE_FAIL = 'GET_All_ACCOUNT_FUND_BALANCE_FAIL';

export const USER_ACCOUNT_FUND_LIST_REQUEST = 'USER_ACCOUNT_FUND_LIST_REQUEST';
export const USER_ACCOUNT_FUND_LIST_SUCCESS = 'USER_ACCOUNT_FUND_LIST_SUCCESS';
export const USER_ACCOUNT_FUND_LIST_FAIL = 'USER_ACCOUNT_FUND_LIST_FAIL';

export const SET_MAX_FUND_WITHDRAWAL_REQUEST = 'SET_MAX_FUND_WITHDRAWAL_REQUEST';
export const SET_MAX_FUND_WITHDRAWAL_SUCCESS = 'SET_MAX_FUND_WITHDRAWAL_SUCCESS';
export const SET_MAX_FUND_WITHDRAWAL_FAIL = 'SET_MAX_FUND_WITHDRAWAL_FAIL';

export const TOGGLE_ACCCOUNT_FUND_REQUEST = 'TOGGLE_ACCCOUNT_FUND_REQUEST';
export const TOGGLE_ACCCOUNT_FUND_SUCCESS = 'TOGGLE_ACCCOUNT_FUND_SUCCESS';
export const TOGGLE_ACCCOUNT_FUND_FAIL = 'TOGGLE_ACCCOUNT_FUND_FAIL';

export const DISABLE_ACCCOUNT_FUND_REQUEST = 'DISABLE_ACCCOUNT_FUND_REQUEST';
export const DISABLE_ACCCOUNT_FUND_SUCCESS = 'DISABLE_ACCCOUNT_FUND_SUCCESS';
export const DISABLE_ACCCOUNT_FUND_FAIL = 'DISABLE_ACCCOUNT_FUND_FAIL';

export const VERIFY_OTP_DISABLE_ACCCOUNT_FUND_REQUEST = 'VERIFY_OTP_DISABLE_ACCCOUNT_FUND_REQUEST';
export const VERIFY_OTP_DISABLE_ACCCOUNT_FUND_SUCCESS = 'VERIFY_OTP_DISABLE_ACCCOUNT_FUND_SUCCESS';
export const VERIFY_OTP_DISABLE_ACCCOUNT_FUND_FAIL = 'VERIFY_OTP_DISABLE_ACCCOUNT_FUND_FAIL';

export const GET_USER_FUND_ACCOUNT_DEBITS_REQUEST = 'GET_USER_FUND_ACCOUNT_DEBITS_REQUEST';
export const GET_USER_FUND_ACCOUNT_DEBITS_SUCCESS = 'GET_USER_FUND_ACCOUNT_DEBITS_SUCCESS';
export const GET_USER_FUND_ACCOUNT_DEBITS_FAIL = 'GET_USER_FUND_ACCOUNT_DEBITS_FAIL';

export const GET_USER_FUND_ACCOUNT_CREDITS_REQUEST = 'GET_USER_FUND_ACCOUNT_CREDITS_REQUEST';
export const GET_USER_FUND_ACCOUNT_CREDITS_SUCCESS = 'GET_USER_FUND_ACCOUNT_CREDITS_SUCCESS';
export const GET_USER_FUND_ACCOUNT_CREDITS_FAIL = 'GET_USER_FUND_ACCOUNT_CREDITS_FAIL';

export const ADMIN_ACTIVATE_ACCCOUNT_FUND_REQUEST = 'ADMIN_ACTIVATE_ACCCOUNT_FUND_REQUEST';
export const ADMIN_ACTIVATE_ACCCOUNT_FUND_SUCCESS = 'ADMIN_ACTIVATE_ACCCOUNT_FUND_SUCCESS';
export const ADMIN_ACTIVATE_ACCCOUNT_FUND_FAIL = 'ADMIN_ACTIVATE_ACCCOUNT_FUND_FAIL';

export const GET_USER_USD_ACCOUNT_FUND_BALANCE_REQUEST = 'GET_USER_USD_ACCOUNT_FUND_BALANCE_REQUEST';
export const GET_USER_USD_ACCOUNT_FUND_BALANCE_SUCCESS = 'GET_USER_USD_FUND_ACCOUNT_BALANCE_SUCCESS';
export const GET_USER_USD_ACCOUNT_FUND_BALANCE_FAIL = 'GET_USER_USD_FUND_ACCOUNT_BALANCE_FAIL';

export const TOGGLE_USD_ACCCOUNT_FUND_REQUEST = 'TOGGLE_USD_ACCCOUNT_FUND_REQUEST';
export const TOGGLE_USD_ACCCOUNT_FUND_SUCCESS = 'TOGGLE_USD_ACCCOUNT_FUND_SUCCESS';
export const TOGGLE_USD_ACCCOUNT_FUND_FAIL = 'TOGGLE_USD_ACCCOUNT_FUND_FAIL';

export const USER_FUND_USD_ACCOUNT_REQUEST = 'USER_FUND_USD_ACCOUNT_REQUEST';
export const USER_FUND_USD_ACCOUNT_SUCCESS = 'USER_FUND_USD_ACCOUNT_SUCCESS';
export const USER_FUND_USD_ACCOUNT_FAIL = 'USER_FUND_USD_ACCOUNT_FAIL';

export const GET_USER_USD_FUND_ACCOUNT_CREDITS_REQUEST = 'GET_USER_USD_FUND_ACCOUNT_CREDITS_REQUEST';
export const GET_USER_USD_FUND_ACCOUNT_CREDITS_SUCCESS = 'GET_USER_USD_FUND_ACCOUNT_CREDITS_SUCCESS';
export const GET_USER_USD_FUND_ACCOUNT_CREDITS_FAIL = 'GET_USER_USD_FUND_ACCOUNT_CREDITS_FAIL';

export const GET_USER_USD_FUND_ACCOUNT_DEBITS_REQUEST = 'GET_USER_USD_FUND_ACCOUNT_DEBITS_REQUEST';
export const GET_USER_USD_FUND_ACCOUNT_DEBITS_SUCCESS = 'GET_USER_USD_FUND_ACCOUNT_DEBITS_SUCCESS';
export const GET_USER_USD_FUND_ACCOUNT_DEBITS_FAIL = 'GET_USER_USD_FUND_ACCOUNT_DEBITS_FAIL';

export const SET_MAX_USD_FUND_WITHDRAWAL_REQUEST = 'SET_MAX_USD_FUND_WITHDRAWAL_REQUEST';
export const SET_MAX_USD_FUND_WITHDRAWAL_SUCCESS = 'SET_MAX_USD_FUND_WITHDRAWAL_SUCCESS';
export const SET_MAX_USD_FUND_WITHDRAWAL_FAIL = 'SET_MAX_USD_FUND_WITHDRAWAL_FAIL';
