// PromiseConstants.js 

export const GET_ALL_PROMISE_REQUEST = 'GET_ALL_PROMISE_REQUEST';
export const GET_ALL_PROMISE_SUCCESS = 'GET_ALL_PROMISE_SUCCESS';
export const GET_ALL_PROMISE_FAIL = 'GET_ALL_PROMISE_FAIL';

export const GET_BUYER_PROMISE_REQUEST = 'GET_BUYER_PROMISE_REQUEST';
export const GET_BUYER_PROMISE_SUCCESS = 'GET_BUYER_PROMISE_SUCCESS';
export const GET_BUYER_PROMISE_FAIL = 'GET_BUYER_PROMISE_FAIL';

export const GET_SELLER_PROMISE_REQUEST = 'GET_SELLER_PROMISE_REQUEST';
export const GET_SELLER_PROMISE_SUCCESS = 'GET_SELLER_PROMISE_SUCCESS';
export const GET_SELLER_PROMISE_FAIL = 'GET_SELLER_PROMISE_FAIL';

export const BUYER_CONFIRM_PROMISE_REQUEST = 'BUYER_CONFIRM_PROMISE_REQUEST';
export const BUYER_CONFIRM_PROMISE_SUCCESS = 'BUYER_CONFIRM_PROMISE_SUCCESS';
export const BUYER_CONFIRM_PROMISE_FAIL = 'BUYER_CONFIRM_PROMISE_FAIL';

export const SELLER_CONFIRM_PROMISE_REQUEST = 'SELLER_CONFIRM_PROMISE_REQUEST';
export const SELLER_CONFIRM_PROMISE_SUCCESS = 'SELLER_CONFIRM_PROMISE_SUCCESS';
export const SELLER_CONFIRM_PROMISE_FAIL = 'SELLER_CONFIRM_PROMISE_FAIL';

export const CREATE_PROMISE_MESSAGE_REQUEST = 'CREATE_PROMISE_MESSAGE_REQUEST';
export const CREATE_PROMISE_MESSAGE_SUCCESS = 'CREATE_PROMISE_MESSAGE_SUCCESS';
export const CREATE_PROMISE_MESSAGE_FAIL = 'CREATE_PROMISE_MESSAGE_FAIL';

// export const LIST_PROMISE_MESSAGE_REQUEST = 'LIST_PROMISE_MESSAGE_REQUEST';
// export const LIST_PROMISE_MESSAGE_SUCCESS = 'LIST_PROMISE_MESSAGE_SUCCESS';
// export const LIST_PROMISE_MESSAGE_FAIL = 'LIST_PROMISE_MESSAGE_FAIL';

export const SETTLE_DISPUTED_PROMISE_REQUEST = 'SETTLE_DISPUTED_PROMISE_REQUEST';
export const SETTLE_DISPUTED_PROMISE_SUCCESS = 'SETTLE_DISPUTED_PROMISE_SUCCESS';
export const SETTLE_DISPUTED_PROMISE_FAIL = 'SETTLE_DISPUTED_PROMISE_FAIL';

export const CANCEL_PROMISE_REQUEST = 'CANCEL_PROMISE_REQUEST';
export const CANCEL_PROMISE_SUCCESS = 'CANCEL_PROMISE_SUCCESS';
export const CANCEL_PROMISE_FAIL = 'CANCEL_PROMISE_FAIL';

export const BUYER_CREATE_PROMISE_MESSAGE_REQUEST = 'BUYER_CREATE_PROMISE_MESSAGE_REQUEST';
export const BUYER_CREATE_PROMISE_MESSAGE_SUCCESS = 'BUYER_CREATE_PROMISE_MESSAGE_SUCCESS';
export const BUYER_CREATE_PROMISE_MESSAGE_FAIL = 'BUYER_CREATE_PROMISE_MESSAGE_FAIL';

export const SELLER_CREATE_PROMISE_MESSAGE_REQUEST = 'SELLER_CREATE_PROMISE_MESSAGE_REQUEST';
export const SELLER_CREATE_PROMISE_MESSAGE_SUCCESS = 'SELLER_CREATE_PROMISE_MESSAGE_SUCCESS';
export const SELLER_CREATE_PROMISE_MESSAGE_FAIL = 'SELLER_CREATE_PROMISE_MESSAGE_FAIL';

export const LIST_BUYER_PROMISE_MESSAGE_REQUEST = 'LIST_BUYER_PROMISE_MESSAGE_REQUEST';
export const LIST_BUYER_PROMISE_MESSAGE_SUCCESS = 'LIST_BUYER_PROMISE_MESSAGE_SUCCESS';
export const LIST_BUYER_PROMISE_MESSAGE_FAIL = 'LIST_BUYER_PROMISE_MESSAGE_FAIL';

export const LIST_SELLER_PROMISE_MESSAGE_REQUEST = 'LIST_SELLER_PROMISE_MESSAGE_REQUEST';
export const LIST_SELLER_PROMISE_MESSAGE_SUCCESS = 'LIST_SELLER_PROMISE_MESSAGE_SUCCESS';
export const LIST_SELLER_PROMISE_MESSAGE_FAIL = 'LIST_SELLER_PROMISE_MESSAGE_FAIL';

export const CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_REQUEST = 'CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_REQUEST';
export const CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_SUCCESS = 'CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_SUCCESS';
export const CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_FAIL = 'CLEAR_BUYER_PROMISE_MESSAGE_COUNTER_FAIL';

export const CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_REQUEST = 'CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_REQUEST';
export const CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_SUCCESS = 'CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_SUCCESS';
export const CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_FAIL = 'CLEAR_SELLEE_PROMISE_MESSAG_COUNTERE_FAIL';
