// messagingConstants.js
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL';

export const GET_MESSAGES_REQUEST = 'GET_MESSAGES_REQUEST';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL';

export const EMAIL_SEND_REQUEST = "EMAIL_SEND_REQUEST";
export const EMAIL_SEND_SUCCESS = "EMAIL_SEND_SUCCESS";
export const EMAIL_SEND_FAIL = "EMAIL_SEND_FAIL";

export const CLEAR_MESSAGE_COUNTER_REQUEST = 'CLEAR_MESSAGE_COUNTER_REQUEST';
export const CLEAR_MESSAGE_COUNTER_SUCCESS = 'CLEAR_MESSAGE_COUNTER_SUCCESS';
export const CLEAR_MESSAGE_COUNTER_FAIL = 'CLEAR_MESSAGE_COUNTER_FAIL';

export const GET_USER_MESSAGES_REQUEST = 'GET_USER_MESSAGES_REQUEST';
export const GET_USER_MESSAGES_SUCCESS = 'GET_USER_MESSAGES_SUCCESS';
export const GET_USER_MESSAGES_FAIL = 'GET_USER_MESSAGES_FAIL';
