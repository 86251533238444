// feedbackConstants.js
export const CREATE_FEEDBACK_REQUEST = 'CREATE_FEEDBACK_REQUEST';
export const CREATE_FEEDBACK_SUCCESS = 'CREATE_FEEDBACK_SUCCESS';
export const CREATE_FEEDBACK_FAIL = 'CREATE_FEEDBACK_FAIL';

export const LIST_FEEDBACK_REQUEST = 'LIST_FEEDBACK_REQUEST';
export const LIST_FEEDBACK_SUCCESS = 'LIST_FEEDBACK_SUCCESS';
export const LIST_FEEDBACK_FAIL = 'LIST_FEEDBACK_FAIL';


