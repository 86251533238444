export const EMAIL_OTP_SEND_REQUEST = "EMAIL_OTP_SEND_REQUEST";
export const EMAIL_OTP_SEND_SUCCESS = "EMAIL_OTP_SEND_SUCCESS";
export const EMAIL_OTP_SEND_FAIL = "EMAIL_OTP_SEND_FAIL";

export const EMAIL_OTP_VERIFY_REQUEST = "EMAIL_OTP_VERIFY_REQUEST";
export const EMAIL_OTP_VERIFY_SUCCESS = "EMAIL_OTP_VERIFY_SUCCESS";
export const EMAIL_OTP_VERIFY_FAIL = "EMAIL_OTP_VERIFY_FAIL";

export const EMAIL_OTP_RESEND_REQUEST = "EMAIL_OTP_RESEND_REQUEST";
export const EMAIL_OTP_RESEND_SUCCESS = "EMAIL_OTP_RESEND_SUCCESS";
export const EMAIL_OTP_RESEND_FAIL = "EMAIL_OTP_RESEND_FAIL";
