
// userConstants.js
export const USER_LOGIN_REQUEST= 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS ='USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const UPDATE_USER_LAST_LOGIN_REQUEST= 'UPDATE_USER_LAST_LOGIN_REQUEST'
export const UPDATE_USER_LAST_LOGIN_SUCCESS ='UPDATE_USER_LAST_LOGIN_SUCCESS'
export const UPDATE_USER_LAST_LOGIN_FAIL = 'UPDATE_USER_LAST_LOGIN_FAIL'

export const USER_LOGOUT= 'USER_LOGOUT'

export const USER_REGISTER_REQUEST= 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS ='USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DELETE_ORDER_REQUEST = "USER_DELETE_ORDER_REQUEST";
export const USER_DELETE_ORDER_SUCCESS = "USER_DELETE_ORDER_SUCCESS";
export const USER_DELETE_ORDER_FAIL = "USER_DELETE_ORDER_FAIL";

export const USER_LOGIN_ACCESS_TOKEN = "USER_LOGIN_ACCESS_TOKEN"; 

